const englishString = {
  oilFilterTxt: "Oil & Filters",
  tireTxt: "Tires",
  batteryTxt: "Batteries",
  supportTxt: "Support Services",
  gurenteeTxt: "(Guarantee & Battery charging)",
  homeNavTxt: "Home",
  yourcarTxt: "Your Cars",
  nocarsaddedTxt: "No cars added yet.",
  plateTxt: "Plate:",
  productNavTxt: "Product",
  customerReviewTxt: "Customer reviews",
  selectcarHead:
    " Please choose one of the methods below to enter your car data.",
  comingsoonTxt:"Coming Soon",
  comingsoonContentTxt:"This feature will be available soon. Stay tuned!",
  closebtnTxt:"Close", 
  aboutNavTxt: "About Us",
  termsconditionTxt:"Term-Conditions",
  contactNavTxt: "Contact Us",
  helpNavTxt: "Help",
  loginNavTxt: "Login",
  tipsNavTxt: "Tips",
  logoutNavTxt: "Logout",
  orderNavTxt: "Order",
  walletNavTxt: "Wallet",
  codeActiveTxt: "Activation Code",
  categoryBtnTxt: "Category",
  productSharinTxt: "Product sharing :",
  welcomeTxt: "Welcome",
  profileTxt: "Profile Details",
  signInTxt: "Sign in",
  signInnnTxt: "Signing in...",

  signUpTxt: "Sign Up",
  sendBtnTxt: "Send",
  sendingBtnTxt: "Sending...",
  otpTxt:"OTP sent successfully!",
  loginsuccessTxt:"Login Successful",
  activCodeTxt: "Enter the activation code sent to your phone number",
  countrySelctTxt: "Select the country",
  loginFirstTxt: "To be able to purchase, log in",
  donthaveAccTxt: " Don't have an account  ",
  creatAccTxt: " Create account",
  emailplaceholderTxt: "Enter your email",
  usernameTxt: "Enter Your Username ",
  privacyTxt1: "I agree to the",
  privacyTxt2: "privacy policy",
  privacyTxt3: "and",
  privacyTxt4: " terms of use",
  
  footerMainTxt:
    `Zurex App,Your one-stop app for car maintenance!
Change oil, filters, batteries, and tires with ease.`,
  footerMainTxt2:
    "Commercial Establishment Isma'il Jum'an al-Jum'an For Trading",
  taxtHeading: "Tax number",
  taxTxtHead: "Tax",
  subTotalTxtHead: "Subtotal",
  anglesTxt: "Angles",
  informationTxt: "Information",
  requeststTxt: "requests",
  accountTxt: "Account",
  productbtmTxt: "Product",
  mReqProductTxt: "Most requested products",
  contactUsLocation:
    "2524, Ibn Zaydoun Street, Al Malaz, Riyadh 12836, Kingdom of Saudi Arabia",
  orderTxt: "Orders",
  faqTxt: "FAQ",
  privacyTxt:"Privacy Policy",
  aboutAppTxt: "About app",
  termCondApp: "Terms & Conditions",
  wholeSaleOrderTxt: "Wholesale orders",
  copyrightTxt: "All our rights reserved 2023 ad",
  firstArticleHeading: "9 Reasons why your car battery suddenly stops working",
  secArticleHeading:
    "10 Secrets Every Woman Should Know Before Buying a new car",
  thirdArticleHeading: "10 rules to follow to protect your car and its battery",
  forthArticleHeading: "7 Tips to keep your car battery in high heat",
  fifthArticleHeading:
    "Car battery or dynamo! Learn how to distinguish between malfunctions?",
  sixthArticleHeading:
    "How do you know when is the right time to replace your car battery?",
  seventhArticleHeading: "7 tips to save your car battery in hot weather",
  nextbtnTxt: "Next",
  engineoilPetrolTxt:"Engine Oil Petrol",
  engineoilDieselTxt:"Engine Oil Diesel",
  filterTxt:"Filters",
  oilsTxt:"Oils",  
  fueltypeTxt:"Select Fuel Type",
  petrolTxt:"Petrol",
  dieselTxt:"Diesel",
  noordersTxt:"No orders found.",
  choosePayMehodTxt: "Choose the payment method that suits you",
  okTxt: "Ok",
  successDoneTxt: "The request has been completed successfully",
  orderIdTxt:"Your Order ID is",
  paymentFailedTxt: "The payment request has been failed",
  paymentCancelTxt: "The payment request has been canceled",
  firstArticle: {
    description:
      "The car battery is the main part in it and the unknown hero that supplies the engine with the energy needed to run it, and also provides its parts with the electricity necessary to ignite them, such as lights, recorder, air conditioning, etc. A car without a battery can do nothing, and for this reason, taking care of the battery has become very important, especially as it is subject to damage due to frequent Reliance on it, and also because of its shelf life, which may reach 5 years, which requires you to replace it at specific periods. And when the battery suddenly stops working, you feel confused and disturbed, so it is important to know the most prominent reasons that lead to weak, damaged, and stopped working battery.",
    descriptionHeading: "Reasons why a car battery suddenly stops working",
    conclutionHeading: "Do you need a new battery?",
    conclutionDescription:
      "After reviewing this information and knowing the various signs of battery damage, you should check your battery and make sure that it is free of these signs. In case you encounter any problem, download the Ac  zurex application, the first application in the Kingdom for the most efficient and quality car batteries with international specifications, which enables you to choose the appropriate battery for your car. And deliver it to you anywhere in the Kingdom with installation service.",
    resonArr: [
      {
        heading: "1. Battery overcharging",
        description:
          "Overcharging the battery causes it to suddenly stop working and damage it, so you have to be careful and check the condition of the electricity regulator if it is working well or if it is broken or there is a mistake in setting it, because all of the aforementioned leads to an increase in the charge in the battery and thus the decomposition and corrosion of the panels inside, which leads to It loses its power and exposes it to damage",
        id: 0,
      },
      {
        heading: "2. Not fully charging the battery",
        description:
          "Malfunctions in the car’s electric system or its failure to adjust it well, in addition to loosening the fan belt in the car, cause the battery to not be fully charged, and the matter does not end here because this leads to the formation of a layer of salts on the battery plates that weakens its performance and exposes it to damage, especially if the defect is not addressed quickly.",
        id: 1,
      },
      {
        heading:
          "3. Corrosion and contamination of the battery electrodes in the car",
        description:
          "You need to pay close attention to the condition of the cables and connect them tightly with the battery terminals and make sure that they are clean and free of malfunctions because any malfunction in this leads to an increase in resistance at the terminals and thus the battery charging efficiency decreases, and then the voltage difference required to operate decreases Car engine",
        id: 2,
      },
      {
        heading: "4. The battery is not securely installed inside the car",
        description:
          "This exposes it to vibration and thus the active substance falls and leaks from the battery plates, in addition to causing the secret of welding between the battery cells, which leads to damage to the battery and its cessation of work",
        id: 3,
      },
      {
        heading:
          "5. Over-tightening the connection and installation of the battery",
        description:
          "It can lead to damage to the battery box and the occurrence of a break or crack in it, and therefore leakage of material from the battery, which leads to damage to it and damage to other parts",
        id: 4,
      },
      {
        heading: "6. Car engine failure",
        description:
          "A clear signal for diagnosing battery damage and a strong indicator of its weakness, and when the engine suffers any malfunction, frequent attempts to start the engine lead to depletion of the battery, which leads to its damage",
        id: 5,
      },
      {
        heading: "7. Frequent sudden stopping of the car while driving",
        description:
          "The reason for this may be damage to the battery, poor quality of the generator or the fuel pump, and for this reason, if the car suddenly stops more than three times a week, most likely it's time to buy a new battery",
        id: 6,
      },
      {
        heading: "8. When to change the car battery",
        description:
          "Each battery has a certain life span and you need to pay attention to the right time to change the battery, as you do not have to wait until it is completely damaged, but you can notice the indicators that tell you the ideal time for this so as to avoid sudden battery malfunctions",
        id: 7,
      },
      {
        heading: "9. Intermittent problems with the car",
        description:
          "If you notice that your car is working well today and the next day you have a problem and so on, this is a sign that the battery has something wrong, such as being broken, its terminals are corroded or suffering from calcification, for this you should check its condition",
        id: 8,
      },
    ],
  },
  secArticle: {
    description:
      "Women spend a lot of money on almost everything from home, trips, etc. However, women feel a different feeling when shopping for a new car, according to Carshop research, as the experience of buying a car for the first time is one of the unique experiences in a woman’s life that she will remember for many years. Especially if she plans it in advance and feels eager to own her first car. Studies also show that 80% of car buying and maintenance decisions are made by women! It's a bit shocking, but women's interests are broad and their role in their family is large and comprehensive, so they always need solutions that help them improve the quality of life of their family, and certainly the car is one of those solutions. And worry..you don't have to. In this blog, we offer you 10 secrets that you should know and think about before buying a new car.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "In the end, we hope that these tips gave you the confidence to buy a new car and enjoy the experience of driving your new car, and in case you are worried about your car battery breaking down while driving, or maybe you need a new battery with high specifications, download the ac zurex application, the first application in the Kingdom for the most efficient and high-quality car batteries And with international specifications, which enables you to choose the right battery for your car and deliver it to you anywhere in the Kingdom with free installation and delivery service.",
    resonArr: [
      {
        heading: "1. Start your search well",
        description:
          "Lack of confidence and lack of experience in cars is the main reason that makes women hesitate to buy a new car, so take the time to conduct comprehensive research and learn about the advantages and additions of each type and the appropriate ones for your budget.",
        id: 0,
      },
      {
        heading: "2. Choose the right time",
        description:
          "Thinking about the right time to visit the car showroom is something that many do not think about despite its importance, because when you go at a suitable time such as early morning or afternoon, this helps you achieve better communication with sales because they are less busy, and the months of February and August are suitable times to conclude a deal good.",
        id: 1,
      },
      {
        heading: "3. Focus on price, not payments",
        description:
          "When buying, most of us focus on payments instead of the total price..and this is a mistake, because monthly or weekly payments often cause more costs and psychological pressure, so one of the things that must be taken into account before making a purchase decision is that the final price includes operating costs, insurances, guarantees, maintenance, and others.",
        id: 2,
      },
      {
        heading: "4. Think about the color of the car",
        description:
          "Sales representatives believe that the color of the car is all that matters to women, and it is an important consideration because when selling a car, the bright yellow color, for example, will not be sold as easily as the silver color, so choosing the color will affect the sale later.",
        id: 3,
      },
      {
        heading: "5. Make sure the car is checked by an expert",
        description:
          "To feel comfortable and reassured, it is better to have the car checked by a specialist before buying it, which could avoid a losing deal.",
        id: 4,
      },
      {
        heading: "6. Think about the right car size",
        description:
          "Whether you have a small family or not, a large car will not suit you, not to mention that it is more expensive and difficult to drive for a woman and causes you a great challenge, so choose a car of a size that fits your lifestyle.",
        id: 5,
      },
      {
        heading: "7. Choose a car that can be handled without complexity",
        description:
          "The first car is often temporary and replaced later, and its purpose is to train and get used to driving, so it is preferable to choose simple manual cars instead of automatics as a first car.",
        id: 6,
      },
      {
        heading: "8. Stay away from sports cars for their powerful engines",
        description:
          "Each battery has a certain life span, and you should pay attention to the appropriate time to change the battery, as you do not have to wait until it is completely damaged, but you can notice the indicators that tell you the ideal time for that in order to avoid sudden battery failures.",
        id: 7,
      },
      {
        heading: "9. Think about maintenance",
        description:
          "It is important to choose a car that is easy to maintain, in addition to that it is necessary to learn the basic rules of car maintenance such as checking the engine oil, car battery, tires and fluids.",
        id: 8,
      },
      {
        heading: "10. It is possible to think about a used car",
        description:
          "A used car is usually a secondary option, but it is not bad, because a used car is often less expensive, but on the other hand, you have to make sure that it is safe and free of malfunctions, because many breakdowns can cost you a lot, and its exposure to some minor accidents will not make you sad as long as it is used. You will replace it later.",
        id: 9,
      },
    ],
  },
  thirdArticle: {
    description:
      "Every driver is keen on the safety and performance of his car because it has become an essential part of his life and he will no longer be able to abandon it. There are signs or signs before the car suddenly stops, but most neglect it. To avoid such sudden breakdowns that put you in a difficult situation, we show you in this blog the 10 most important rules that, if you follow them, will surely preserve your car and its battery for as long as possible.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "At the end of the blog, we strongly recommend that you follow the previous rules and adhere to them, in order to preserve your safety from stopping in the middle of the road and the safety of your car as well, as they are all simple rules that you can easily adhere to. In the event that you are concerned about your car battery breaking down while driving, or you may need a new battery with high specifications, download the AC Xerox application, the first application in the Kingdom for the most efficient and high-quality car batteries with international specifications, which enables you to choose the appropriate battery for your car and deliver it to you anywhere in the Kingdom with Free installation and delivery service.",
    resonArr: [
      {
        heading: "1. Save your car battery power",
        description:
          "Just say no to short trips, and avoid driving close distances because your car battery needs time to charge and frequent short trips lead to consuming its energy without being able to fully recharge.",
        id: 0,
      },
      {
        heading: "2. Don't hesitate to hire maintenance",
        description:
          "There are cases that you may face that you will not be able to deal with despite your experience in dealing with your car, as the mechanic remains the first person with experience, so do not hesitate to ask for his help and advice when needed, which saves you a lot of time and effort.",
        id: 1,
      },
      {
        heading: "3. Protect your car from the hot sun",
        description:
          "The damage of bright sunlight, especially in the summer season, is great and very harmful to your car and its battery, so be sure to park the car in the shade or garage, and make sure the condition and stability of the battery before the summer comes to avoid the worst.",
        id: 2,
      },
      {
        heading: "4. Clean the battery frequently",
        description:
          "Any battery of any type needs constant care, especially in terms of cleaning it, because a few sediments and salts are enough to cause great damage to the battery and its electrodes, so make sure to clean it constantly and carefully and protect it from wear and tear.",
        id: 3,
      },
      {
        heading: "5. Visit the mechanic at the right time",
        description:
          "Always choose the appropriate time to visit the mechanic and perform the required maintenance, that is, when you notice any unusual signs in the car or the battery, you should immediately go to the maintenance to avoid sudden breakdowns and stop in the middle of the road. Choose a professional and qualified technician to maintain your car's electrical system, and ensure the quality and efficiency of spare parts.",
        id: 4,
      },
      {
        heading: "6. Make sure the battery is connected correctly",
        description:
          "Because when the battery poles are incorrectly connected, this causes serious problems such as burning all or some of the electronics, especially in the absence of a protection circuit, including burning some lighting LEDs, failure of chargers, and melting the internal components of the battery, which causes leakage of toxic gases. So be careful and make sure the plug is correct.",
        id: 5,
      },
      {
        heading: "7. Prepare for the best, expect the worst",
        description:
          "And here we mean the commitment to the periodic inspection of your car, because this is very important, especially with the change of weather factors, surrounding conditions, and your driving habits, all of which have a great impact on it, and in particular on the life and performance of the battery, for this reason commit to periodic maintenance with a specialized mechanic and do a proactive test of the battery voltage to protect it from Penetration or damage while driving.",
        id: 6,
      },
      {
        heading: "8. Read your car's owner's manual",
        description:
          "Looking at the guide book or as it is called the catalog is one of the important steps to know how to maintain your car, so take enough time to read it and get to know your car more and do not let the enthusiasm drive you to drive it ignorantly.",
        id: 7,
      },
      {
        heading: "9. Change the engine oil",
        description:
          "Be sure to change the engine oil on time and do not neglect it because it has great risks to your car and the engine, and be careful not to start the car with the oil warning sign lit on the dashboard, and then you should go to the specialized technician and make the required repairs.",
        id: 8,
      },
      {
        heading: "10. Check brakes and tires",
        description:
          "Keep your car tires as much as possible and stay away from unpaved roads if your car is not equipped to drive on it, and make sure to check the air pressure in the tires periodically, in addition to checking the brakes as well, in order to preserve your safety, as the brakes are one of the important parts of the car and you should not be complacent with any a problem or a change in it.",
        id: 9,
      },
    ],
  },
  forthArticle: {
    description:
      "Is it possible to keep the car battery as long as possible? How can its life be increased for a longer period? And many similar questions asked by people who own a car and want to preserve it and its safety in the best way, given the importance of the car battery and its role in its performance. It has problems and stops on the side of the road. From high temperatures to the habits of some drivers and other factors that can affect the life and strength of the battery, you cannot at the same time rely on the default battery life, which can be from 3-5 years. There are many methods that you can follow to help you maintain your car battery for as long as possible, and this is what we will discuss in this blog.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "In conclusion, you can make sure that the car battery can last for a long time if it is properly taken care of and you follow the advice we mentioned to you. The battery is the basis of your car and you must take care of it and do what is required to maintain it, otherwise you will spend long days in battery stores in search of the best battery. For this reason, we offer you AC Xerox batteries, manufactured in Malaysia, and guarantee long-lasting quality. Download the AC Xerox application and enjoy the highest and best international specifications.",
    resonArr: [
      {
        heading: "1. Avoid short trips and driving close distances",
        description:
          "As it is known, driving short distances and going to close places does not give your car battery enough time to recharge properly, because starting the engine requires a lot of battery energy, and frequent driving for short distances leads to rapid battery discharge and difficulty in recharging it, and for this reason the car may not start. Next time because there is not enough power in the battery to turn it on.",
        id: 0,
      },
      {
        heading: "2. Don't leave your car idle for too long",
        description:
          "This is considered one of the worst habits that can harm your car battery, because stopping the car for a long time and parking it in storage for more than two weeks leads to depletion or weakness of the battery, even if it is new, and also frequent discharges as a result of non-use can shorten its life. The battery is draining quickly.",
        id: 1,
      },
      {
        heading: "3. Driving long distances",
        description:
          "It is sure that long trips in your car is really fun and more comfortable and makes your commute easier, but you should not forget to give your car battery enough time to fully recharge because long distances consume a lot of its energy.",
        id: 2,
      },
      {
        heading: "4. Keep the battery clean",
        description:
          "The corrosion of the terminals of the car battery is not new, but it needs attention and care continuously and not intermittently and for long periods, because this has a serious impact on the performance of the battery, as the sulfuric acid inside the battery leads to the accumulation of materials that cause corrosion of its terminals and cables, which increases the electrical resistance And makes the battery work hard. This is why keeping the battery terminals clean is a simple but necessary task.",
        id: 3,
      },
      {
        heading: "5. Make sure your battery is secured in place",
        description:
          "You have to check your car battery regularly, not only to avoid its corrosion, but also to make sure that it is installed in the correct position inside the car, because placing the battery in an unstable and tight manner leads to shortening its life span, not to mention the vibrations that the battery will be exposed to while driving, which leads to its rapid damage and stop it. Work.Besides, you also have to make sure that they are connected correctly, because the cables that are not connected well create great resistance between the battery terminals, which makes the battery exert double effort until it works normally, and thus leads to a malfunction in its work and some electrical problems.",
        id: 4,
      },
      {
        heading:
          "6. Pay attention to the battery consumption of the accessories",
        description:
          "Your car's battery not only uses its energy to start the engine, but also provides its energy to operate the air conditioning, lights and other accessories, so you can save your battery by not turning on the accessories when you start the car, in addition to that you have to give the battery a rest period after an accessory has consumed it for a long time It's like forgetting one of your car's lights is on all night, so pay close attention and make sure the accessories are turned off and unplugged before you get out of the car.",
        id: 5,
      },
      {
        heading: "7. Test your car battery regularly",
        description:
          "The best way to keep the car battery for a long time is to make sure that it is tested and checked regularly, because this will clearly show you its condition, how strong it is, and whether there are any new problems, in addition to helping you know the appropriate time to replace it. All of this protects you from exposure to dangerous situations such as your car stopping in the middle of the road due to a malfunction in its battery and other difficult situations.",
        id: 6,
      },
    ],
  },

  fifthArticle: {
    description:
      "If you are having difficulty starting your car or experiencing sudden shutdowns, then there is a good chance that the car battery or dynamo is to blame! Perhaps it is worse than that and the reason is both. For this reason, continue reading this blog to learn more about how the battery and dynamo work and the most important indicators of damage.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "To know more indicators and signs that tell you that it is time to replace your car battery..Check out this blog “How do you know if your car battery is time to replace it?” If you tested your car battery and it works fine, but you are still having difficulty starting the engine, you should make sure From the cleanliness of the wires and battery electrodes from corrosion and sediment, then you can simply clean them, but if this does not work, you must check the five indicators that we mentioned.",
    resonArr: [
      {
        heading: "The difference between a dynamo and a battery",
        description:
          "Both the battery and the dynamo provide the energy needed to operate your car properly, so both are very necessary for the operation of any vehicle, and despite the overlapping tasks of each of them, they provide the necessary energy at different stages since the start of the car.",
        id: 0,
      },
      {
        heading: "What is the role of the car battery?",
        description:
          "You can consider your car battery like coffee, as it provides the energy needed to start the car engine, when you turn the key to start, the battery sends energy to the ignition, and despite the battery’s strength and performance, it is unable to make the car run for a long time on its own..and here comes the role of the dynamo.",
        id: 1,
      },
      {
        heading: "What is the role of the dynamo in the car?",
        description:
          "Once the car is started, the dynamo recharges your battery and takes over the task of providing the energy needed for almost all electronic components in your car such as windows, windshield wipers, front and rear lights and heating, and the electric steering system, in addition to the dashboard lights and meters and the radio, as it works to convert mechanical energy derived From the motor to electrical power through a belt and pulley system that connects it to the motor and to the internal alternator.",
        id: 2,
      },
      {
        heading:
          "How do you know if the malfunction is in the dynamo or in the battery?",
        description:
          "A damaged battery and a broken dyno cause various damages to your appointments and trips, especially if most of them are related to work! Therefore, it is important for you to monitor the indicators that indicate when any malfunction or problem occurs. At first glance, you may find it difficult because you will not be able to distinguish between the indicators of a broken dynamo and a damaged battery, but this becomes easier after you get to know the most prominent indicators of malfunctions.",
        id: 3,
      },
      {
        heading: "The most prominent indicators of a damaged battery",
        description:
          "Unfortunately, batteries generally last for about three to five years. But despite this, there are many factors that can affect its performance and drain its energy, which makes its end near, such as parking the car for a long time in the garage without working for more than two weeks and also living in areas with extreme heat or dry cold, all of the above Mentioning it is a factor that reduces battery life, making you vulnerable to difficult situations and stopping in the middle of the road, so be careful of the following indicators if you notice any of them:",
        bulletsPoints: [
          {
            id: 0,
            title: "Difficulty starting the car",
          },
          {
            id: 0,
            title: "Hearing strange sounds when starting your car",
          },
          { id: 0, title: "Lighting of the battery sign on the dashboard" },
          { id: 0, title: "Swelling in the battery" },
          { id: 0, title: "Notice strange and disturbing smells" },
        ],
        id: 4,
      },
    ],
  },
  sixArticle: {
    description:
      "According to experts, the best time to replace your car battery is before you actually need it, and this is a result of reaching the stage of conviction in replacing the battery preceded by many annoying and tiring situations for you, such as battery failure, difficulty in starting the car, etc. Knowing the right time to replace the battery is not easy, but there are some Indicators that can tell you that, and this is what you will learn about in this blog.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "At the end of the blog, we strongly recommend that you follow the previous rules and adhere to them, in order to preserve your safety from stopping in the middle of the road and the safety of your car as well, as they are all simple rules that you can easily adhere to. In the event that you are concerned about your car battery breaking down while driving, or you may need a new battery with high specifications, download the AC Xerox application, the first application in the Kingdom for the most efficient and high-quality car batteries with international specifications, which enables you to choose the appropriate battery for your car and deliver it to you anywhere in the Kingdom with Free installation and delivery service.",
    resonArr: [
      {
        heading: "4 signs that tell you your car battery is time to replace:",
        description:
          "When there is something wrong with your car, you will definitely notice that, such as if you notice some strange smells and sounds. Anything that happens out of the ordinary indicates that your car needs to be examined and detected. Here are some common warning signs that indicate that your car battery is weak, so you should pay attention to her:",
        id: 0,
      },
      {
        heading: "",
        description:
          "1. Difficulty starting the car engine, as you will notice that starting the car requires time and many attempts, and this is a clear and possible indication that the battery is weak and that it is at its last stage.",
        id: 99,
      },
      {
        heading: "",
        description:
          "2. Lighting of the battery sign on the dashboard is an indicator that you should not ignore, although there are various reasons for this, but most of the time it indicates a problem with the charging system or damage to the battery.",
        id: 1,
      },
      {
        heading: "",
        description:
          "3. The appearance of swelling in the battery and deformation of its shape, and this occurs as a result of overcharging the battery or exposure to high temperatures, and this indicator is conclusive evidence of the need to replace the battery",
        id: 2,
      },
      {
        heading: "",
        description:
          "4. Liquid leakage from the battery, as liquid batteries tend to leak acid as a result of overcharging or damage, and in any case, when this happens, you must replace them immediately.",
        id: 3,
      },
      {
        heading: "",
        description:
          "All of the previous indicators help you know that your car’s battery has expired, and that you need to visit the maintenance workshop as soon as possible, which is tiring and annoying for most drivers, but it is much better than things getting worse and the car stopping you in the middle of the road. And if you do not notice any of the previous indicators This does not mean that you are far from trouble, because there are many factors that consume your battery without their effect being clear until it is too late. So testing your car battery on a regular basis can help you make sure it's okay and working well.",
        id: 4,
      },
      {
        heading: "How often should you replace your car battery?",
        description:
          "There is no battery that lasts forever, but in general three years is considered a normal period for replacing a battery, but at other times you may have to replace it before the expiration of three years due to climate factors, heat, and bad driving habits, all of this affects the battery life and performance, in addition to the type The battery and its manufacturer can also play a role. In order to make sure that the battery is replaced in a timely manner, stick to its regular check-up and do not neglect it.",
        id: 5,
      },
      {
        heading: "Climate is a key factor for battery replacement",
        description:
          "Whether you live in areas with high temperatures or in cold and dry areas, in both cases your car battery will be affected by this, but the high temperature is more harmful and faster than the low temperature, as it causes battery weakness and deterioration in performance due to the evaporation of water inside, which leads to rapid corrosion and sediment. And the loss of the battery, for this reason, be careful of the high temperature and pay attention to any change that may occur to it. As for the cold climate, when the intensity of the wind increases and the temperature drops, the battery needs additional effort to generate enough energy to operate the car normally, in addition to increasing the density of the engine oil in cold weather This causes additional stress on the battery.",
        id: 6,
      },
      {
        heading: "Don't let the weather affect your battery",
        description:
          "After you have a clear idea of the effect of climate on the car battery, today you can choose a battery that suits the climate of the region in which you live, as the types of batteries are countless and have different advantages, so do not hesitate to choose the most suitable for your car.",
        id: 7,
      },
      {
        heading: "The influence of driving habits on car battery replacement",
        description:
          "Driving habits and the way you deal with your car and use it affect the battery. If you drive the car for short distances continuously and daily, the battery will not have enough time to fully recharge between trips, which leads to reduced performance and reduced battery life. Also, if you park your car for long periods, The battery continues to run low even if the engine is off, so pay close attention to your habits before the time runs out.",
        id: 8,
      },
    ],
  },
  seventhArticle: {
    description:
      "Do you feel a little lethargic and tired during the summer? Does the high temperature affect your activity during the day? So the same applies to your car battery. As high temperatures can affect the battery, and simply destroy it, as the high temperature affects everything and affects it, so you have to be more aware and prepared to receive the summer season and its heat. In this blog, you will learn how high temperatures affect your car battery and some tips that enable you to deal with it in the best possible way.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "At the end of the blog, we strongly recommend that you follow the previous rules and adhere to them, in order to preserve your safety from stopping in the middle of the road and the safety of your car as well, as they are all simple rules that you can easily adhere to. In the event that you are concerned about your car battery breaking down while driving, or you may need a new battery with high specifications, download the AC Xerox application, the first application in the Kingdom for the most efficient and high-quality car batteries with international specifications, which enables you to choose the appropriate battery for your car and deliver it to you anywhere in the Kingdom with Free installation and delivery service.",
    resonArr: [
      {
        heading: "How does high temperature affect your car battery?",
        description:
          "Life seems much more difficult when you have to live in hot areas, because suddenly you need a glass of cold water after a short walk near your house! Heat causes you to dry out to a large extent, and the same thing with your car battery, as it was found that high temperatures can lead to evaporation of fluids inside the battery and weaken its charge, which means dryness and damage. In addition, high temperatures can accelerate the corrosion process, which means damage to the internal structure of the battery and its complete loss. Note that most sealed car batteries that do not need maintenance have a water level indicator inside the battery to let you know if the water is enough or low. For this, you have to make some effort to maintain your car battery during the high temperatures of the year, regardless of where you live, which may not enable you to avoid high temperatures, but you can benefit from the following tips to preserve your car battery and extend its life as much as possible, because There are some behaviors and habits that can seriously harm your car battery... Pay attention to them, and follow the tips below.",
        id: 0,
      },
      {
        heading: "",
        description:
          "1. Avoid short trips and driving for close distances, especially in the summer, because this does not provide the battery with enough time to fully recharge between short trips and downtime, which leads to poor performance and damage in a short time.",
        id: 1,
      },
      {
        heading: "",
        description:
          "2. Make sure to turn off the internal and external lights when leaving the car, because high temperatures lead to high self-discharge of the battery, and therefore you will have to charge the battery many times even if it is stopped, and do not operate it frequently.",
        id: 2,
      },
      {
        heading: "",
        description:
          "3. Keep the battery and wires clean at all times, because dirt accumulates quickly, and impedes the charging of the battery, which leads to its draining and poor charging.",
        id: 3,
      },
      {
        heading: "",
        description:
          "4. Avoid using electronic devices such as radios when the car engine is off.",
        id: 4,
      },
      {
        heading: "",
        description:
          "5.Park your car in the shade or in the garage to protect it from direct sunlight.",
        id: 5,
      },
      {
        heading: "",
        description:
          "6. Many batteries contain a thermal insulator that protects them from high temperatures. Make sure that the insulator is firmly in place.",
        id: 6,
      },
      {
        heading: "",
        description:
          "7. Pay attention to signs that your car battery has a problem or needs maintenance.",
        id: 7,
      },
    ],
    boldHeading: {
      boldDesc:
        "Fortunately, most of these indicators have become clear and known to everyone, and they warn you of the problem at an earlier time to avoid the risks of stopping in the middle of the road, and among the most prominent indicators that you should pay attention to:",
      bulletPoints: [
        {
          title:
            "The engine is running more slowly than usual when the car is running.",
          id: 0,
        },
        {
          title:
            "Lighting of the check engine indicator or the battery indicator on the dashboard.",
          id: 1,
        },
        { title: "Low liquid level inside the battery", id: 2 },
        { title: "Bulge in the shape of the battery", id: 3 },
        { title: "Corrosion of battery electrodes", id: 4 },
        { title: "Weak interior lights and headlights", id: 5 },
        { title: "Your battery life is more than three years", id: 6 },
      ],
    },
  },
  balanceTxt: "Balance (Cash Back)",
  balanceSimpTxt: "Balance",
  supportServiceTxt: "Support services (Battery charge and warranty)",
  currencyTxt: "SAR",
  cartTxt: "Cart",
  favoriteToastTxt1:"Product added to favorites",
  favoriteToastTxt2:"This product is already in your favorites",
addsubscriptionTxt:"Add examination, subscription and Warranty Service.",
youSaveTxt1:" You saved",
youSaveTxt2:" SAR just now!",
  totalTxt: "Total",
  discountTxt:"Discount",
  serviceChargeTxt: "Service Charge",
  subTotalTxt:"Sub-Total",
  TaxTxt:"Tax",
  priceTxt:"Price",
  warrantyChargeTxt:"Subscription",

  ordertTxt: "My Orders",
  completOrder: "Complete Order",
  shopNowTxt: "Shop Now",
  emptyCrtTxt: "Cart is empty, shop now",
  emptyFvrtTxt: "Your favorite products will be shown here",
  addFavriteTxt: "Add to favorites",
  addedFavriteTxt: "Added to favorites",
  nameInputTxt: "Name",
  emailInputTxt: "Email",
  phoneInputTxt: "Phone No.",
  messageInputTxt: "Message",
  changeBatteryTxt: "Battery charging",
  maintainBatteryTxt: "Maintenance (batteries)",
  WarentyBatteryTxtCharge: "Warranty and battery charging",
  senBtnTxt: "Send",
  itemNumTxt: "Item No. ",
  downloadAppTxtNew: "Download the app now",
  AmpereTxt: "Ampere ",
  lengthTxt: "Length ",
  widthTxt: "Width ",
  heightTxt: "Height ",
  totalHeightTxt: "Total height ",
  coolEngTxt: "Cool energy cycling ",
  orderYourTxt: "Order your car ",
  orderYourTxt2: " Now, delivery and installation for free",
  batteryDescTxt:
    "An electronic car battery made in Malaysia that does not require maintenance",
  oilDescTxt: "Malaysian Industry maintenance-free electronic car oil",
  tireDescTxt:
    "An electronic car tire made in Malaysia that does not require maintenance",
  helpArray: [
    {
      title: "What types of batteries do we provide?",
      subHeading:
        "We offer all types of ac zurex batteries, made in Malaysia, that last for a full year, as we are the exclusive agent for this brand in the Kingdom of Saudi Arabia.",
      id: 0,
    },
    {
      title: "What about the quality of the batteries",
      subHeading:
        "All products we sell meet the requirements set by the relevant country of Saudi Arabia",
      id: 1,
    },
    {
      title: "Why there is a big difference in prices",
      subHeading:
        "The difference is due to the quality and quality of the batteries.",
      id: 2,
    },
    {
      title: "How can I buy a battery from ac zurex store",
      subHeading:
        "Enter your data on the store, then choose the preferred battery to the cart list, then fill in the payment information, and we will contact you and send the battery to your place with our installation team.",
      id: 3,
    },
    {
      title: "Is the battery under warranty",
      subHeading:
        "Each battery comes with a standard warranty.The warranty is void if the battery is used in the wrong way",
      id: 4,
    },
    {
      title: "Can I buy a battery from you and ship it to my country",
      subHeading:
        "Yes, we can ship a large amount to you via your shipping companies. Please contact us via email or technical support",
      id: 5,
    },
    {
      title: "Is there a refund and replacement",
      subHeading:
        "There is no return or exchange, but only according to the warranty card that came with the battery",
      id: 6,
    },
    {
      title: "Can my old car battery be replaced with an ac zurex battery",
      subHeading:
        "Yes, you can replace your battery with a battery from ac zurex with a discount of 30 SAR on a code",
      id: 7,
    },
  ],
  aboutUsArray: [
    {
      title: "About Us",
      subHeading:
        "The zurex app is a one stop application for Ismail Al Jamaan Al Hamrani Trading Company, a leader in the field of car batteries, car tires and the import of car parts and accessories, at the level of the kingdom of Saudi Arabia, and our activity and services extend to a wide range throughout the kingdom through our branches .It is the exclusive agent of the AC ZUREX and CAMEL brand in the kingdom.",
      id: 0,
    },
    {
      title: "Our Service",
      subHeading:
        "We work at Ismail Al Jamaan Al Hamrani Trading Company to provide the best types of car batteries, car tires, car parts and accessories to all our customers with the required quality, we are one of the leading distributors of various brands and of batteries and tires from Korea, Taiwan and Indonesia ,Thailand, China, homemade.",
      id: 1,
    },
    {
      title: "Our vision",
      subHeading:
        "The Corporation intends to be the leader in its field in the wholesale and retail trade of auto products, spare parts and accessories, and the sale of high-quality car and truck batteries and tires in the Kingdom, and to achieve its vision through sustainable growth through innovation in development and prosperity with the best international products and the provision of an advanced workforce of engineers and consultants.",
      id: 2,
    },
    {
      title: "Our goals",
      subHeading:
        "Providing the best quality products and effective after-sales services at the national level, and we are working to develop them in parallel with the local and international markets with high-quality environmentally friendly products and a guarantee. Customer satisfaction is the goal of our company, and we want to constantly reach and explore global markets.",
      id: 3,
    },
    {
      title: "Our values",
      subHeading:
        "The real wealth of the company lies in its solid relationships. We consider it the cornerstone in the construction and management of our company, which is one of the constants that we do not deviate from, and strives to understand their requirements and needs.",
      id: 4,
    },
  ],
  favriteTxt: "Favorite",
  carTypeTxt: "Type of car",
  carCategTxt: "Choose the car category",
  carModalTxt: "Choose the car model",
  plateNumTxtInp: "Write plate number",
  addCarsTxt: "Add Cars",
  addCarsBtn: "Add Car",
  nextBtn: "Next",
  loadingBtn: "Loading...",
  pay4Txt:"Pay in 4. No interest, no fees.",
  payment1Txt:"Electronic Payment",
  payment2Txt:"Cash Payment",
  payment3Txt:"Installment Companies",
  payment4Txt:"Installment Companies",

  plsSlctCarTxt: "Please select a car before selecting a product category.",
  addToCartTxt: "Add to cart",
  enterYourCarDataTxt: "Enter your car data",
  enterCarManual: "Manual Car Entry",
  enterCarILM: "Enter Plate Number",
  enterCarPlateDetails: "Enter Car Plate Details",
  selectcarmethod: " Select Car Entry Method",
  enterplateAlphabets: "Enter Plate Alphabets",
  enterplateNumbers: "Enter Plate Numbers",
  vehicledetailsTxt: "Vehicle Details",
  makeTxt: "Make:",
  modelTxt: "Model:",
  modelyearTxt: "Model Year:",
  registrationtypeTxt: "Registration Type:",
  vehicalclassTxt: "Vehicle Class:",
  vehicalIdNumberTxt: "Vehicle ID Number:",
  cylinderTxt: "Cylinder:",

  downloadAppTxt: "Download the application now",
  chooseShippingWay: "Shipping Information",
  backBtnTxt: "Back",
  yourAddress: "Your Address",
  strretTownAddress: "Your Street , Town , Provience",
  selectDeliveryDate: "Select your delivery date",
  zipcode:"ZipCode",
  userReviewTxt:"The services are very excellent , and the order arrived well, faster and with more than wonderful quality, I wish you good luck",
  tabbyPaymentCancel : 'You aborted the payment. Please retry or choose another payment method.',
  tabbyPaymentFailed : 'Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order',
  tabbyGeneralError: 'Sorry, Tabby is unable to approve this purchase. Please use an alternative payment method for your order.',
  tabbyOrderHighError: 'This purchase is above your current spending limit with Tabby, try a smaller cart or use another payment method',
  tabbyTooLowError: 'The purchase amount is below the minimum amount required to use Tabby, try adding more items or use another payment method'


};
const arabicString = {
  oilFilterTxt: "الزيوت والفلاتر",
  tireTxt: "الإطارات",
  batteryTxt: "البطاريات",
  supportTxt: "خدمات الدعم",
  gurenteeTxt: "(الضمان وشحن البطارية)",
  homeNavTxt: "الرئيسية",
  yourcarTxt: "سياراتك",
  nocarsaddedTxt: "لم تتم إضافة أي سيارات حتى الآن.",
  plateTxt: "اللوحة",
  productNavTxt: "المنتج",
  customerReviewTxt: "آراء العملاء",
  selectcarHead: "يرجى اختيار إحدى الطرق أدناه لإدخال بيانات سيارتك.",
  comingsoonTxt:"قريباً",
  comingsoonContentTxt:"هذه الميزة ستكون متاحة قريبا. ابقوا متابعين!",
  aboutNavTxt: "معلومات عنا",
  termsconditionTxt:"بنود الاتفاقية",
closebtnTxt:"يغلق",
  contactNavTxt: "اتصل بنا",
  helpNavTxt: "الاسئلة الشائعة",
  loginNavTxt: "تسجيل الدخول",
  tipsNavTxt: "نصائح",
  orderNavTxt: "الطلبات",
  logoutNavTxt: "تسجيل خروج",
  walletNavTxt: "المحفظة",
  codeActiveTxt: "رمز التفعيل",
  categoryBtnTxt: "الفئة",
  productSharinTxt: "مشاركة المنتج :",
  welcomeTxt: "مرحباً",
  profileTxt: "تفاصيل الملف الشخصي",

  signInTxt: "تسجيل الدخول",
  signInnnTxt: "...تسجيل الدخول",

  signUpTxt: "اشتراك",
  sendBtnTxt: "دخول",
  sendingBtnTxt: "...إرسال",
  otpTxt:"!تم إرسال كلمة المرور لمرة واحدة بنجاح",
  loginsuccessTxt:"تم تسجيل الدخول بنجاح",

  activCodeTxt: "أدخل رمز التفعيل المرسل إلى رقم هاتفك",
  countrySelctTxt: "اختر البلد",
  loginFirstTxt: "لتتمكن من الشراء، قم بتسجيل الدخول",
  donthaveAccTxt: "ليس لديك حساب",
  creatAccTxt: "إنشاء حساب",
  emailplaceholderTxt: "أدخل بريدك الإلكتروني",
  usernameTxt: "أدخل اسم المستخدم الخاص بك",
  privacyTxt1: "أناأوافق على",
  privacyTxt2: "سياسة الخصوصية",
  privacyTxt3: "و",
  privacyTxt4: "شروط الاستخدام",
  footerMainTxt:" تطبيق زيوركس,  كل ما تحتاجه لصيانة سيارتك في تطبيق واحد! تغيير زيت، فلاتر، بطاريات وإطارات ",
// footerMainTxt:`تطبيق زيوركس،  كل ما تحتاجه لصيانة سيارتك في تطبيق واحد
//                         تغيير زيت، فلاتر، بطاريات وإطارات`,
                        
  taxtHeading: "الرقم الضريبي",
  taxTxtHead: "الضريبة",
  subTotalTxtHead: "المجموع الفرعي",
  anglesTxt: "الزوايا",
  informationTxt: "معلومات",
  requeststTxt: "الطلبات",
  accountTxt: "الحساب",
  productbtmTxt: "المنتج",
  mReqProductTxt: "المنتجات الأكثر طلبا",
  contactUsLocation: "2524، شارع ابن زيدون، الملز، الرياض 12836، المملكة العربية السعودية.",
  orderTxt: "الطلبات",
  faqTxt: "الاسئلة الشائعة",
  aboutAppTxt: "حول التطبيق",
  termCondApp: "الشروط والاحكام",
  wholeSaleOrderTxt: "طلبات الجملة",
  copyrightTxt: "جميع حقوقنا محفوظة 2024 إعلان",
  firstArticleHeading: "9 أسباب وراء توقف بطارية سيارتك عن العمل فجأة",
  secArticleHeading: "10 أسرار يجب أن تعرفها كل امرأة قبل شراء سيارة جديدة",
  thirdArticleHeading: "10 قواعد عليك اتباعها لحماية سيارتك وبطاريتها",
  forthArticleHeading: "7 نصائح للحفاظ على بطارية سيارتك في الحرارة المرتفعة",
  fifthArticleHeading:
    "بطارية السيارة أو الدينامو! تعلم كيفية التمييز بين الأعطال؟",
  sixthArticleHeading: "كيف تعرف الوقت المناسب لاستبدال بطارية سيارتك؟",
  seventhArticleHeading: "7 نصائح لحفظ بطارية سيارتك في الطقس الحار",
  nextbtnTxt: "التالي",
  engineoilPetrolTxt: "زيت محرك البنزين",
engineoilDieselTxt: "زيت محرك الديزل",
filterTxt: "الفلاتر",
oilsTxt: "الزيوت",
fueltypeTxt: "اختر نوع الوقود",
petrolTxt: "بنزين",
dieselTxt: "ديزل",
noordersTxt:"لم يتم العثور على أي أوامر.",
  choosePayMehodTxt: "اختر طريقة الدفع التي تناسبك",
  okTxt: "نعم",
  successDoneTxt: "تم إكمال الطلب بنجاح",
  orderIdTxt:"معرف الطلب الخاص بك هو",
  paymentFailedTxt: "لقد فشل طلب الدفع",
  paymentCancelTxt: "تم إلغاء طلب الدفع",
  firstArticle: {
    description:
      "بطارية السيارة هي الجزء الرئيسي فيها والبطل المجهول الذي يزود المحرك بالطاقة اللازمة لتشغيله، كما يزود أجزائه بالكهرباء اللازمة لإشعالها، مثل الأضواء، والمسجل، وتكييف الهواء وغيرها. السيارة بدون بطارية لا يمكنها أن تفعل شيئاً، ولهذا السبب أصبح الاهتمام بالبطارية أمراً في غاية الأهمية، خاصة أنها معرضة للتلف بسبب كثرة الاعتماد عليها، وأيضاً بسبب عمرها الافتراضي الذي قد يصل إلى 5 سنوات. مما يتطلب منك استبداله في فترات محددة. وعندما تتوقف البطارية عن العمل فجأة، تشعر بالارتباك والانزعاج، لذا من المهم معرفة أبرز الأسباب التي تؤدي إلى ضعف البطارية وتلفها وتوقفها عن العمل.",
    descriptionHeading: "أسباب توقف بطارية السيارة عن العمل فجأة",
    conclutionHeading: "هل تحتاج إلى بطارية جديدة؟",
    conclutionDescription:
      "بعد الاطلاع على هذه المعلومات ومعرفة العلامات المختلفة لتلف البطارية، عليك فحص بطاريتك والتأكد من خلوها من هذه العلامات. في حال واجهتك أي مشكلة قم بتحميل تطبيق Ac zurex التطبيق الأول في المملكة لبطاريات السيارات الأكثر كفاءة وجودة بمواصفات عالمية والذي يمكنك من اختيار البطارية المناسبة لسيارتك. وتوصيلها إليك في أي مكان بالمملكة مع خدمة التركيب.",
    resonArr: [
      {
        heading: "1. الشحن الزائد للبطارية",
        description:
          "الشحن الزائد للبطارية يؤدي إلى توقفها عن العمل بشكل مفاجئ وتلفها، لذا عليك الحذر والتحقق من حالة منظم الكهرباء إذا كان يعمل بشكل جيد أو إذا كان معطلاً أو هناك خطأ في ضبطه، لأن جميع ما سبق يؤدي إلى زيادة الشحن في البطارية وبالتالي تحلل وتآكل الألواح بداخلها مما يؤدي إلى فقدانها لطاقتها ويعرضها للتلف",
        id: 0,
      },
      {
        heading: "2. عدم شحن البطارية بالكامل",
        description:
          "أعطال في النظام الكهربائي للسيارة أو عدم ضبطه بشكل جيد، بالإضافة إلى ارتخاء حزام المروحة في السيارة، يؤدي إلى عدم شحن البطارية بالكامل، ولا ينتهي الأمر هنا لأن ذلك يؤدي إلى تكوين طبقة من الأملاح الموجودة على ألواح البطارية مما يضعف أداءها ويعرضها للتلف، خاصة إذا لم تتم معالجة الخلل سريعاً.",
        id: 1,
      },
      {
        heading: "3. تآكل وتلوث أقطاب البطارية في السيارة",
        description:
          "عليك الانتباه جيداً لحالة الكابلات وربطها بإحكام مع أطراف البطارية والتأكد من نظافتها وخلوها من الأعطال لأن أي خلل في ذلك يؤدي إلى زيادة المقاومة عند الأطراف وبالتالي شحن البطارية فتقل الكفاءة، ومن ثم يقل فرق الجهد اللازم لتشغيل محرك السيارة",
        id: 2,
      },
      {
        heading: "4. البطارية غير مثبتة بشكل آمن داخل السيارة",
        description:
          "مما يعرضها للاهتزاز وبالتالي سقوط المادة الفعالة وتسربها من صفائح البطارية بالإضافة إلى التسبب في سر اللحام بين خلايا البطارية مما يؤدي إلى تلف البطارية وتوقفها عن العمل",
        id: 3,
      },
      {
        heading: "5. الإفراط في ربط توصيل البطارية وتركيبها",
        description:
          "يمكن أن يؤدي إلى تلف صندوق البطارية وحدوث كسر أو شرخ فيه، وبالتالي تسرب المواد من البطارية مما يؤدي إلى تلفها وتلف الأجزاء الأخرى",
        id: 4,
      },
      {
        heading: "6. عطل محرك السيارة",
        description:
          "إشارة واضحة لتشخيص تلف البطارية ومؤشر قوي على ضعفها، وعندما يتعرض المحرك لأي عطل فإن المحاولات المتكررة لتشغيل المحرك تؤدي إلى نفاذ البطارية مما يؤدي إلى تلفها",
        id: 5,
      },
      {
        heading: "7. التوقف المفاجئ المتكرر للسيارة أثناء القيادة",
        description:
          "قد يكون السبب في ذلك هو تلف البطارية، أو سوء جودة المولد أو مضخة الوقود، ولهذا السبب، إذا توقفت السيارة فجأة أكثر من ثلاث مرات في الأسبوع، فمن المرجح أن الوقت قد حان لشراء بطارية جديدة.",
        id: 6,
      },
      {
        heading: "8. متى يتم تغيير بطارية السيارة؟",
        description:
          "كل بطارية لها عمر معين وعليك الانتباه إلى الوقت المناسب لتغيير البطارية، حيث لا يتعين عليك الانتظار حتى تتلف بشكل كامل، ولكن يمكنك ملاحظة المؤشرات التي تخبرك بالوقت المثالي لذلك لتجنب أعطال البطارية المفاجئة",
        id: 7,
      },
      {
        heading: "9. مشاكل متقطعة في السيارة",
        description:
          "إذا لاحظت أن سيارتك تعمل بشكل جيد اليوم وفي اليوم التالي واجهت مشكلة وما إلى ذلك، فهذه علامة على أن البطارية بها خطأ ما، مثل أن تكون مكسورة أو تآكل أطرافها أو تعاني من التكلس، لهذا عليك يجب التحقق من حالته",
        id: 8,
      },
    ],
  },
  secArticle: {
    description:
      "تنفق النساء الكثير من المال على كل شيء تقريبًا من المنزل والرحلات وما إلى ذلك. ومع ذلك، تشعر النساء بشعور مختلف عند التسوق لشراء سيارة جديدة، وفقًا لأبحاث كارشوب، حيث تعد تجربة شراء سيارة لأول مرة واحدة من أكثر التجارب متعة. تجارب فريدة في حياة المرأة ستتذكرها لسنوات عديدة. خاصة إذا كانت تخطط لذلك مسبقًا وتشعر بالرغبة في امتلاك سيارتها الأولى. كما تشير الدراسات إلى أن 80% من قرارات شراء وصيانة السيارات تتخذها النساء! الأمر صادم بعض الشيء، لكن اهتمامات المرأة واسعة ودورها في أسرتها كبير وشامل، لذا فهي تحتاج دائمًا إلى حلول تساعدها على تحسين نوعية حياة أسرتها، وبالتأكيد السيارة هي أحد تلك الحلول. والقلق.. ليس عليك ذلك. وفي هذه المدونة نقدم لك 10 أسرار عليك معرفتها والتفكير فيها قبل شراء سيارة جديدة.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "وفي النهاية، نأمل أن أعطتك هذه النصائح الثقة لشراء سيارة جديدة والاستمتاع بتجربة قيادة سيارتك الجديدة، وفي حال كنت قلقًا بشأن تعطل بطارية سيارتك أثناء القيادة، أو ربما تحتاج إلى بطارية جديدة بمواصفات عالية حمل تطبيق ac zurex التطبيق الأول في المملكة لبطاريات السيارات الأكثر كفاءة وعالية الجودة وبمواصفات عالمية والذي يمكنك من اختيار البطارية المناسبة لسيارتك وتوصيلها إليك في أي مكان في المملكة المملكة مع خدمة التركيب والتوصيل مجاناً.",
    resonArr: [
      {
        heading: "1. ابدأ بحثك جيدًا",
        description:
          "إن عدم الثقة وقلة الخبرة في السيارات هو السبب الرئيسي الذي يجعل النساء يترددن في شراء سيارة جديدة، لذلك خذي الوقت الكافي لإجراء بحث شامل والتعرف على مميزات وإضافات كل نوع والمناسبة لميزانيتك.",
        id: 0,
      },
      {
        heading: "2. اختر الوقت المناسب",
        description:
          "التفكير في الوقت المناسب لزيارة معرض السيارات هو أمر لا يفكر فيه الكثيرون رغم أهميته، لأنك عندما تذهب في وقت مناسب مثل الصباح الباكر أو بعد الظهر، فإن ذلك يساعدك على تحقيق تواصل أفضل مع المبيعات لأنهم أقل انشغالاً ويعتبر شهري فبراير وأغسطس من الأوقات المناسبة لإبرام صفقة جيدة.",
        id: 1,
      },
      {
        heading: "3. التركيز على السعر، وليس المدفوعات",
        description:
          "عند الشراء يركز معظمنا على الدفعات بدلا من السعر الإجمالي.. وهذا خطأ، لأن الدفعات الشهرية أو الأسبوعية غالبا ما تسبب المزيد من التكاليف والضغط النفسي، لذلك من الأشياء التي يجب مراعاتها قبل الشراء القرار هو أن السعر النهائي يشمل تكاليف التشغيل والتأمينات والضمانات والصيانة وغيرها.",
        id: 2,
      },
      {
        heading: "4. فكر في لون السيارة",
        description:
          "يعتقد مندوبي المبيعات أن لون السيارة هو كل ما يهم المرأة، وهو اعتبار مهم لأنه عند بيع السيارة، فإن اللون الأصفر الساطع، على سبيل المثال، لن يتم بيعه بسهولة مثل اللون الفضي، لذلك اختيار اللون سيؤثر اللون على البيع لاحقًا.",
        id: 3,
      },
      {
        heading: "5. التأكد من فحص السيارة لدى خبير",
        description:
          "وللشعور بالراحة والطمأنينة، من الأفضل فحص السيارة لدى متخصص قبل شرائها، وهو ما قد يجنبك صفقة خاسرة.",
        id: 4,
      },
      {
        heading: "6. فكر في الحجم المناسب للسيارة",
        description:
          "سواء كان لديك عائلة صغيرة أم لا، فإن السيارة الكبيرة لن تناسبك، ناهيك عن أنها أغلى ثمناً وصعبة القيادة بالنسبة للمرأة وتسبب لك تحدياً كبيراً، لذا اختر سيارة بالحجم الذي يناسب نمط حياتك .",
        id: 5,
      },
      {
        heading: "7. اختر سيارة يمكن التعامل معها دون تعقيد",
        description:
          "السيارة الأولى غالباً ما تكون مؤقتة ويتم استبدالها لاحقاً، والغرض منها هو التدريب والتعود على القيادة، لذلك يفضل اختيار السيارات اليدوية البسيطة بدلاً من الأوتوماتيكية كسيارة أولى.",
        id: 6,
      },
      {
        heading: "8. الابتعاد عن السيارات الرياضية لمحركاتها القوية",
        description:
          "كل بطارية لها عمر افتراضي معين، ويجب الانتباه إلى الوقت المناسب لتغيير البطارية، حيث لا يتعين عليك الانتظار حتى تتلف بشكل كامل، ولكن يمكنك ملاحظة المؤشرات التي تخبرك بالوقت المثالي لذلك في لتجنب أعطال البطارية المفاجئة.",
        id: 7,
      },
      {
        heading: "9. فكر في الصيانة",
        description:
          "من المهم اختيار سيارة يسهل صيانتها، بالإضافة إلى أنه من الضروري تعلم القواعد الأساسية لصيانة السيارة مثل فحص زيت المحرك وبطارية السيارة والإطارات والسوائل.",
        id: 8,
      },
      {
        heading: "10. من الممكن التفكير في سيارة مستعملة",
        description:
          "عادة ما تكون السيارة المستعملة خيارا ثانويا، ولكنها ليست سيئة، لأن السيارة المستعملة غالبا ما تكون أقل تكلفة، ولكن من ناحية أخرى عليك التأكد من أنها آمنة وخالية من الأعطال، لأن العديد من الأعطال يمكن أن تكلفك كثيراً، كما أن تعرضه لبعض الحوادث البسيطة لن يحزنك طالما تم استخدامه. سوف تقوم باستبداله لاحقا.",
        id: 9,
      },
    ],
  },
  thirdArticle: {
    description:
      "يحرص كل سائق على سلامة وأداء سيارته لأنها أصبحت جزءاً أساسياً من حياته ولن يتمكن بعد الآن من التخلي عنها. هناك علامات أو إشارات قبل أن تتوقف السيارة فجأة، ولكن أغلبها يهملها. ولتفادي مثل هذه الأعطال المفاجئة التي تضعك في موقف صعب، نعرض لك في هذه المدونة أهم 10 قواعد التي إذا اتبعتها ستحافظ بالتأكيد على سيارتك وبطاريتها لأطول فترة ممكنة.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "وفي نهاية المدونة ننصحك بشدة بإتباع القواعد السابقة والإلتزام بها، حفاظاً على سلامتك من التوقف في منتصف الطريق وسلامة سيارتك أيضاً، فكلها قواعد بسيطة التي يمكنك الالتزام بها بسهولة. في حال كنت قلقاً بشأن تعطل بطارية سيارتك أثناء القيادة، أو قد تحتاج إلى بطارية جديدة بمواصفات عالية، قم بتحميل تطبيق AC Xerox، التطبيق الأول في المملكة لبطاريات السيارات الأكثر كفاءة وعالية الجودة مع المواصفات العالمية والتي تمكنك من اختيار البطارية المناسبة لسيارتك وتوصيلها لك في أي مكان بالمملكة مع خدمة التركيب والتوصيل مجاناً.",
    resonArr: [
      {
        heading: "1. وفر طاقة بطارية سيارتك",
        description:
          "فقط قل لا للرحلات القصيرة، وتجنب القيادة لمسافات قريبة لأن بطارية سيارتك تحتاج إلى وقت للشحن والرحلات القصيرة المتكررة تؤدي إلى استهلاك طاقتها دون القدرة على إعادة شحنها بالكامل.",
        id: 0,
      },
      {
        heading: "2. لا تتردد في استئجار الصيانة",
        description:
          "هناك حالات قد تواجهك لن تتمكن من التعامل معها رغم خبرتك في التعامل مع سيارتك، حيث يبقى الميكانيكي هو صاحب الخبرة الأول، فلا تتردد في طلب مساعدته ونصائحه عند الحاجة، والتي يوفر عليك الكثير من الوقت والجهد.",
        id: 1,
      },
      {
        heading: "3. احمي سيارتك من أشعة الشمس الحارقة",
        description:
          "أضرار أشعة الشمس الساطعة وخاصة في فصل الصيف كبيرة ومضرة جداً لسيارتك وبطاريتها، لذا احرص على ركن السيارة في الظل أو الجراج، وتأكد من حالة البطارية وثباتها قبل حلول الصيف. يأتي لتجنب الأسوأ.",
        id: 2,
      },
      {
        heading: "4. قم بتنظيف البطارية بشكل متكرر",
        description:
          "أي بطارية مهما كان نوعها تحتاج إلى عناية مستمرة، خاصة فيما يتعلق بتنظيفها، لأن القليل من الرواسب والأملاح كافية لإحداث ضرر كبير للبطارية وأقطابها، لذا احرص على تنظيفها باستمرار وبعناية وحمايتها من التآكل والتلف. يٌقطِّع.",
        id: 3,
      },
      {
        heading: "5. قم بزيارة الميكانيكي في الوقت المناسب",
        description:
          "اختر دائماً الوقت المناسب لزيارة الميكانيكي وإجراء الصيانة المطلوبة، أي عند ملاحظة أي علامات غير عادية في السيارة أو البطارية عليك التوجه فوراً إلى الصيانة لتجنب الأعطال المفاجئة والتوقف في منتصف الطريق . اختر فنياً محترفاً ومؤهلاً لصيانة النظام الكهربائي لسيارتك، والتأكد من جودة وكفاءة قطع الغيار.",
        id: 4,
      },
      {
        heading: "6. تأكد من توصيل البطارية بشكل صحيح",
        description:
          "لأنه عندما يتم توصيل أقطاب البطارية بشكل غير صحيح فإن ذلك يسبب مشاكل خطيرة مثل احتراق كل أو بعض الإلكترونيات وخاصة في حالة عدم وجود دائرة حماية، بما في ذلك احتراق بعض مصابيح الإضاءة LED وفشل أجهزة الشحن وذوبان المكونات الداخلية للبطارية مما يسبب تسرب الغازات السامة. لذا كن حذرًا وتأكد من صحة القابس.",
        id: 5,
      },
      {
        heading: "7. استعد للأفضل، وتوقع الأسوأ",
        description:
          "ونقصد هنا الالتزام بالفحص الدوري لسيارتك، لأن ذلك مهم جداً، خاصة مع تغير العوامل الجوية والظروف المحيطة وعادات القيادة الخاصة بك، فكلها لها تأثير كبير عليها، وبشكل خاص عليها. عمر البطارية وأدائها، ولهذا السبب يجب الالتزام بالصيانة الدورية لدى ميكانيكي متخصص وإجراء اختبار استباقي لجهد البطارية لحمايتها من الاختراق أو التلف أثناء القيادة.",
        id: 6,
      },
      {
        heading: "8. اقرأ دليل مالك سيارتك",
        description:
          "إن الاطلاع على كتاب الدليل أو كما يسمى الكتالوج هو من الخطوات المهمة لمعرفة كيفية صيانة سيارتك، لذا خذ الوقت الكافي لقراءته والتعرف على سيارتك أكثر ولا تجعل الحماس يدفعك للقيادة ذلك بجهل.",
        id: 7,
      },
      {
        heading: "9. تغيير زيت المحرك",
        description:
          "احرص على تغيير زيت المحرك في الوقت المحدد ولا تهمل ذلك لما فيه من مخاطر كبيرة على سيارتك والمحرك، واحذر من تشغيل السيارة وعلامة الزيت التحذيرية مضاءة على لوحة القيادة، ومن ثم عليك التوجه إلى الفني المختص وإجراء الإصلاحات المطلوبة.",
        id: 8,
      },
      {
        heading: "10. فحص الفرامل والإطارات",
        description:
          "حافظ على إطارات سيارتك قدر الإمكان وابتعد عن الطرق غير المعبدة إذا كانت سيارتك غير مهيأة للقيادة عليها، وتأكد من فحص ضغط الهواء في الإطارات بشكل دوري، بالإضافة إلى فحص الفرامل أيضاً، من أجل حافظ على سلامتك، فالفرامل من الأجزاء المهمة في السيارة ويجب ألا تتهاون بأي مشكلة أو تغيير فيها.",
        id: 9,
      },
    ],
  },
  forthArticle: {
    description:
      "هل يمكن الاحتفاظ ببطارية السيارة أطول فترة ممكنة؟ وكيف يمكن زيادة عمره لفترة أطول؟ والعديد من الأسئلة المشابهة التي يطرحها الأشخاص الذين يمتلكون سيارة ويريدون الحفاظ عليها وعلى سلامتها بأفضل الطرق، نظراً لأهمية بطارية السيارة ودورها في أدائها. فيه مشاكل ويتوقف على جانب الطريق. من درجات الحرارة المرتفعة إلى عادات بعض السائقين وغيرها من العوامل التي يمكن أن تؤثر على عمر البطارية وقوتها، لا يمكنك في نفس الوقت الاعتماد على عمر البطارية الافتراضي، والذي يمكن أن يكون من 3-5 سنوات. هناك العديد من الطرق التي يمكنك اتباعها لتساعدك في الحفاظ على بطارية سيارتك لأطول فترة ممكنة، وهذا ما سنتناوله في هذه المدونة.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "وفي الختام، يمكنك التأكد من أن بطارية السيارة يمكن أن تستمر لفترة طويلة إذا تم الاعتناء بها بشكل صحيح واتبعت النصائح التي ذكرناها لك. البطارية هي أساس سيارتك ويجب عليك الاهتمام بها والقيام بما يلزم للحفاظ عليها، وإلا ستقضي أياماً طويلة في متاجر البطاريات بحثاً عن أفضل بطارية. ولهذا السبب، نقدم لك بطاريات AC Xerox، المصنعة في ماليزيا، وتضمن جودة تدوم طويلاً. حمل تطبيق ايه سي زيروكس واستمتع بأعلى وأفضل المواصفات العالمية.",
    resonArr: [
      {
        heading: "1. تجنب الرحلات القصيرة والقيادة لمسافات متقاربة",
        description:
          "كما هو معروف، فإن القيادة لمسافات قصيرة والذهاب إلى الأماكن القريبة لا يمنح بطارية سيارتك الوقت الكافي لإعادة شحنها بشكل صحيح، لأن تشغيل المحرك يتطلب الكثير من طاقة البطارية، كما أن القيادة المتكررة لمسافات قصيرة تؤدي إلى تفريغ البطارية بسرعة وصعوبة في ذلك. إعادة شحنها، ولهذا السبب قد لا تعمل السيارة. في المرة القادمة لأنه لا توجد طاقة كافية في البطارية لتشغيلها.",
        id: 0,
      },
      {
        heading: "2. لا تترك سيارتك خاملة لفترة طويلة",
        description:
          "ويعتبر هذا من أسوأ العادات التي يمكن أن تضر بطارية سيارتك، لأن إيقاف السيارة لفترة طويلة وركنها في المخزن لأكثر من أسبوعين يؤدي إلى استنزاف البطارية أو ضعفها حتى لو كانت جديدة، وأيضاً التصريفات المتكررة نتيجة عدم الاستخدام يمكن أن تقصر من عمرها. البطارية تستنزف بسرعة.",
        id: 1,
      },
      {
        heading: "3. القيادة لمسافات طويلة",
        description:
          "من المؤكد أن الرحلات الطويلة في سيارتك ممتعة حقًا وأكثر راحة وتجعل تنقلاتك أسهل، لكن لا يجب أن تنسى إعطاء بطارية سيارتك وقتًا كافيًا لإعادة شحنها بالكامل لأن المسافات الطويلة تستهلك الكثير من طاقتها.",
        id: 2,
      },
      {
        heading: "4. حافظ على نظافة البطارية",
        description:
          "إن تآكل أطراف بطارية السيارة ليس بالأمر الجديد، ولكنه يحتاج إلى عناية ورعاية بشكل مستمر وليس بشكل متقطع ولفترات طويلة، لما لذلك من تأثير خطير على أداء البطارية، حيث يؤدي حمض الكبريتيك الموجود داخل البطارية إلى تراكم المواد التي تسبب تآكل أطرافها وكابلاتها، مما يزيد من المقاومة الكهربائية ويجعل البطارية تعمل بجهد. هذا هو السبب في أن الحفاظ على نظافة أطراف البطارية يعد مهمة بسيطة ولكنها ضرورية.",
        id: 3,
      },
      {
        heading: "5. تأكد من تثبيت البطارية في مكانها",
        description:
          "عليك فحص بطارية سيارتك بانتظام، ليس فقط لتجنب تآكلها، بل للتأكد أيضاً من تركيبها في الوضع الصحيح داخل السيارة، لأن وضع البطارية بطريقة غير مستقرة ومحكمة يؤدي إلى تقصير عمرها الافتراضي، ناهيك عن الاهتزازات التي ستتعرض لها البطارية أثناء القيادة، مما يؤدي إلى تلفها السريع وتوقفها. العمل.إلى جانب ذلك عليك أيضًا التأكد من توصيلها بشكل صحيح، لأن الكابلات التي لا يتم توصيلها بشكل جيد تخلق مقاومة كبيرة بين أطراف البطارية، مما يجعل البطارية تبذل جهدًا مضاعفًا حتى تعمل بشكل طبيعي، وبالتالي يؤدي إلى عطلها. في عمله وبعض المشاكل الكهربائية.",
        id: 4,
      },
      {
        heading: "6. انتبه إلى استهلاك بطارية الملحقات",
        description:
          "بطارية سيارتك لا تستخدم طاقتها لتشغيل المحرك فحسب، بل توفر طاقتها أيضًا لتشغيل مكيف الهواء والأضواء وغيرها من الملحقات، لذا يمكنك توفير بطاريتك من خلال عدم تشغيل الملحقات عند تشغيل السيارة، بالإضافة إلى أنه يجب عليك إعطاء البطارية فترة راحة بعد استهلاك أحد الملحقات لها لفترة طويلة، وكأنك نسيت أن أحد مصابيح سيارتك مضاء طوال الليل، لذا انتبه جيدًا وتأكد من إيقاف تشغيل الملحقات وفصلها قبل أن تحصل على خارج السيارة.",
        id: 5,
      },
      {
        heading: "7. اختبر بطارية سيارتك بانتظام",
        description:
          "بطارية سيارتك لا تستخدم طاقتها لتشغيل المحرك فحسب، بل توفر طاقتها أيضًا لتشغيل مكيف الهواء والأضواء وغيرها من الملحقات، لذا يمكنك توفير بطاريتك من خلال عدم تشغيل الملحقات عند تشغيل السيارة، بالإضافة إلى أنه يجب عليك إعطاء البطارية فترة راحة بعد استهلاك أحد الملحقات لها لفترة طويلة، وكأنك نسيت أن أحد مصابيح سيارتك مضاء طوال الليل، لذا انتبه جيدًا وتأكد من إيقاف تشغيل الملحقات وفصلها قبل أن تحصل على خارج السيارة.",
        id: 6,
      },
    ],
  },

  fifthArticle: {
    description:
      "إذا كنت تواجه صعوبة في تشغيل سيارتك أو تعاني من إيقاف التشغيل المفاجئ، فهناك احتمال كبير أن يكون السبب هو بطارية السيارة أو الدينامو! ولعل الأمر أسوأ من ذلك والسبب كلاهما. ولهذا السبب تابع قراءة هذه المدونة لتتعرف أكثر على كيفية عمل البطارية والدينامو وأهم مؤشرات التلف.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "لمعرفة المزيد من المؤشرات والعلامات التي تخبرك أن الوقت قد حان لاستبدال بطارية سيارتك.. راجع هذه المدونة “كيف تعرف إذا كان وقت استبدال بطارية سيارتك قد حان؟” إذا قمت باختبار بطارية سيارتك وأنها تعمل بشكل جيد، ولكنك لا تزال تواجه صعوبة في تشغيل المحرك، فيجب عليك التأكد من نظافة الأسلاك وأقطاب البطارية من التآكل والرواسب، فيمكنك ببساطة تنظيفها، أما إذا حدث ذلك لا يعمل، يجب عليك التحقق من المؤشرات الخمسة التي ذكرناها.",
    resonArr: [
      {
        heading: "الفرق بين الدينامو والبطارية",
        description:
          "توفر كل من البطارية والدينامو الطاقة اللازمة لتشغيل سيارتك بشكل سليم، لذا فإن كلاهما ضروري جدًا لتشغيل أي مركبة، وعلى الرغم من تداخل المهام لكل منهما، إلا أنهما يوفران الطاقة اللازمة في مراحل مختلفة منذ بداية السيارة.",
        id: 0,
      },
      {
        heading: "ما هو دور بطارية السيارة؟",
        description:
          "يمكنك اعتبار بطارية سيارتك مثل القهوة، فهي توفر الطاقة اللازمة لتشغيل محرك السيارة، فعندما تقوم بتشغيل المفتاح لبدء التشغيل، ترسل البطارية الطاقة إلى جهاز الإشعال، وعلى الرغم من قوة البطارية وأدائها إلا أنها غير قادرة على صنع تعمل السيارة لفترة طويلة من تلقاء نفسها.. وهنا يأتي دور الدينامو.",
        id: 1,
      },
      {
        heading: "ما هو دور الدينامو في السيارة؟",
        description:
          "بمجرد تشغيل السيارة، يقوم الدينامو بإعادة شحن بطاريتك ويتولى مهمة توفير الطاقة اللازمة لجميع المكونات الإلكترونية في سيارتك تقريبًا مثل النوافذ، ومساحات الزجاج الأمامي، والأضواء الأمامية والخلفية والتدفئة، ونظام التوجيه الكهربائي، في بالإضافة إلى أضواء لوحة القيادة والعدادات والراديو، حيث تعمل على تحويل الطاقة الميكانيكية المستمدة من المحرك إلى طاقة كهربائية من خلال نظام الحزام والبكرة التي تربطها بالمحرك وبالمولد الداخلي.",
        id: 2,
      },
      {
        heading: "كيف تعرف إذا كان العطل في الدينامو أم في البطارية؟",
        description:
          "البطارية التالفة والدينامو المكسور يتسببان في أضرار مختلفة لمواعيدك ورحلاتك، خاصة إذا كان معظمها يتعلق بالعمل! لذلك، من المهم بالنسبة لك مراقبة المؤشرات التي تشير إلى حدوث أي عطل أو مشكلة. للوهلة الأولى قد تجد الأمر صعباً لأنك لن تتمكن من التمييز بين مؤشرات الدينمو المكسور والبطارية التالفة، لكن هذا يصبح أسهل بعد أن تتعرف على أبرز مؤشرات الأعطال.",
        id: 3,
      },
      {
        heading: "أبرز مؤشرات تلف البطارية",
        description:
          "ولسوء الحظ، تدوم البطاريات بشكل عام لمدة تتراوح بين ثلاث إلى خمس سنوات. لكن رغم ذلك هناك عوامل كثيرة من الممكن أن تؤثر على أدائها وتستنزف طاقتها مما يجعل نهايتها قريبة مثل ركن السيارة لفترة طويلة في الجراج دون العمل لأكثر من أسبوعين وأيضا العيش في مناطق شديدة الكثافة الحرارة أو البرودة الجافة، كل ما سبق ذكره هو من العوامل التي تقلل من عمر البطارية، مما يجعلك عرضة للمواقف الصعبة والتوقف في منتصف الطريق، لذا انتبه للمؤشرات التالية إذا لاحظت أياً منها:",
        bulletsPoints: [
          {
            id: 0,
            title: "صعوبة في تشغيل السيارة",
          },
          {
            id: 0,
            title: "سماع أصوات غريبة عند تشغيل السيارة",
          },
          { id: 0, title: "إضاءة علامة البطارية على لوحة القيادة" },
          { id: 0, title: "تورم في البطارية" },
          { id: 0, title: "ملاحظة الروائح الغريبة والمزعجة" },
        ],
        id: 4,
      },
    ],
  },
  sixArticle: {
    description:
      "بحسب الخبراء فإن أفضل وقت لاستبدال بطارية سيارتك هو قبل أن تحتاج إليها فعليا، وهذا نتيجة وصولك إلى مرحلة الاقتناع باستبدال البطارية التي يسبقها العديد من المواقف المزعجة والمتعبة بالنسبة لك، مثل فشل البطارية، صعوبة في تشغيل السيارة وغيرها. معرفة الوقت المناسب لاستبدال البطارية ليس بالأمر السهل، ولكن هناك بعض المؤشرات التي يمكن أن تخبرك بذلك، وهذا ما ستتعرف عليه في هذه المدونة.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "وفي نهاية المدونة ننصحك بشدة بإتباع القواعد السابقة والإلتزام بها، حفاظاً على سلامتك من التوقف في منتصف الطريق وسلامة سيارتك أيضاً، فكلها قواعد بسيطة التي يمكنك الالتزام بها بسهولة. في حال كنت قلقاً بشأن تعطل بطارية سيارتك أثناء القيادة، أو قد تحتاج إلى بطارية جديدة بمواصفات عالية، قم بتحميل تطبيق AC Xerox، التطبيق الأول في المملكة لبطاريات السيارات الأكثر كفاءة وعالية الجودة مع المواصفات العالمية والتي تمكنك من اختيار البطارية المناسبة لسيارتك وتوصيلها لك في أي مكان بالمملكة مع خدمة التركيب والتوصيل مجاناً.",
    resonArr: [
      {
        heading: "4 علامات تخبرك أن بطارية سيارتك قد حان وقت استبدالها:",
        description:
          "عندما يكون هناك خطأ ما في سيارتك، ستلاحظ ذلك بالتأكيد، كأن تلاحظ بعض الروائح والأصوات الغريبة. أي شيء يحدث خارج عن المألوف يدل على أن سيارتك بحاجة للفحص والكشف. فيما يلي بعض العلامات التحذيرية الشائعة التي تشير إلى أن بطارية سيارتك ضعيفة، لذا عليك الانتباه لها:",
        id: 0,
      },
      {
        heading: "",
        description:
          "1. صعوبة تشغيل محرك السيارة، حيث ستلاحظ أن تشغيل السيارة يحتاج إلى وقت ومحاولات عديدة، وهذا مؤشر واضح ومحتمل على أن البطارية ضعيفة وأنها في مرحلتها الأخيرة.",
        id: 99,
      },
      {
        heading: "",
        description:
          "2. إن إضاءة علامة البطارية على لوحة القيادة مؤشر لا يجب أن تتجاهله، ورغم أن هناك أسباباً مختلفة لذلك، إلا أنها في أغلب الأحيان تشير إلى وجود مشكلة في نظام الشحن أو تلف البطارية.",
        id: 1,
      },
      {
        heading: "",
        description:
          "3. ظهور انتفاخ في البطارية وتشوه شكلها ويحدث ذلك نتيجة الشحن الزائد للبطارية أو التعرض لدرجات حرارة عالية، وهذا المؤشر دليل قاطع على ضرورة استبدال البطارية",
        id: 2,
      },
      {
        heading: "",
        description:
          "4. تسرب السائل من البطارية، حيث أن البطاريات السائلة تميل إلى تسرب الحمض نتيجة الشحن الزائد أو التلف، وفي كل الأحوال عند حدوث ذلك يجب عليك استبدالها على الفور.",
        id: 3,
      },
      {
        heading: "",
        description:
          "جميع المؤشرات السابقة تساعدك على معرفة أن بطارية سيارتك قد انتهت صلاحيتها، وأنك بحاجة لزيارة ورشة الصيانة في أسرع وقت ممكن، وهو أمر متعب ومزعج لأغلب السائقين، لكنه أفضل بكثير من تفاقم الأمور وتدهور السيارة. توقفك في منتصف الطريق. وإذا لم تلاحظ أيًا من المؤشرات السابقة، فهذا لا يعني أنك بعيد عن المشاكل، فهناك عوامل كثيرة تستهلك بطاريتك دون أن يتضح تأثيرها إلا بعد فوات الأوان. لذا فإن اختبار بطارية سيارتك بشكل منتظم يمكن أن يساعدك على التأكد من أنها بخير وتعمل بشكل جيد.",
        id: 4,
      },
      {
        heading: "كم مرة يجب عليك استبدال بطارية سيارتك؟",
        description:
          "لا توجد بطارية تدوم إلى الأبد، لكن بشكل عام تعتبر ثلاث سنوات فترة طبيعية لاستبدال البطارية، لكن في أحيان أخرى قد تضطر إلى استبدالها قبل انقضاء ثلاث سنوات بسبب عوامل المناخ والحرارة وعادات القيادة السيئة. كل هذا يؤثر على عمر البطارية وأدائها، بالإضافة إلى نوع البطارية والشركة المصنعة لها يمكن أن يلعبا دورًا أيضًا. للتأكد من استبدال البطارية في الوقت المناسب، التزم بفحصها الدوري ولا تهمله.",
        id: 5,
      },
      {
        heading: "المناخ هو العامل الرئيسي لاستبدال البطارية",
        description:
          "سواء كنت تعيش في مناطق ذات درجات حرارة عالية أو في مناطق باردة وجافة، ففي كلتا الحالتين سوف تتأثر بطارية سيارتك بهذا الأمر، ولكن ارتفاع درجة الحرارة أكثر ضررا وأسرع من انخفاض درجة الحرارة، حيث أنها تسبب ضعف البطارية وتدهور الأداء بسبب تبخر الماء بداخلها، مما يؤدي إلى التآكل السريع والترسبات. وفقدان البطارية، لهذا السبب يجب الحذر من ارتفاع درجة حرارتها وانتبه لأي تغيير قد يحدث لها. أما بالنسبة للمناخ البارد، فعندما تزداد شدة الرياح وتنخفض درجة الحرارة، تحتاج البطارية إلى جهد إضافي لتوليد طاقة كافية لتشغيل السيارة بشكل طبيعي، بالإضافة إلى زيادة كثافة زيت المحرك في الطقس البارد مما يسبب إجهاداً إضافياً على البطارية.",
        id: 6,
      },
      {
        heading: "لا تدع الطقس يؤثر على بطاريتك",
        description:
          "بعد أن أصبحت لديك فكرة واضحة عن تأثير المناخ على بطارية السيارة، يمكنك اليوم اختيار بطارية تناسب مناخ المنطقة التي تعيش فيها، فأنواع البطاريات لا تعد ولا تحصى ولها مزايا مختلفة، فلا تتردد لاختيار الأنسب لسيارتك.",
        id: 7,
      },
      {
        heading: "تأثير عادات القيادة على استبدال بطارية السيارة",
        description:
          "عادات القيادة وطريقة تعاملك مع سيارتك واستخدامها تؤثر على البطارية. إذا كنت تقود السيارة لمسافات قصيرة بشكل مستمر ويومي، فلن يتوفر للبطارية الوقت الكافي لإعادة شحنها بالكامل بين الرحلات، مما يؤدي إلى انخفاض الأداء وانخفاض عمر البطارية. كذلك، إذا ركنت سيارتك لفترات طويلة، فإن البطارية تستمر في النفاد حتى لو كان المحرك متوقفاً، لذا انتبه جيداً لعاداتك قبل نفاذ الوقت.",
        id: 8,
      },
    ],
  },
  seventhArticle: {
    description:
      "هل تشعر ببعض الخمول والتعب خلال فصل الصيف؟ هل يؤثر ارتفاع درجة الحرارة على نشاطك خلال النهار؟ لذا الأمر نفسه ينطبق على بطارية سيارتك. حيث أن درجات الحرارة المرتفعة يمكن أن تؤثر على البطارية، وتدمرها بكل بساطة، كما أن ارتفاع درجة الحرارة يؤثر على كل شيء ويؤثر عليها، لذلك عليك أن تكون أكثر وعياً واستعداداً لاستقبال فصل الصيف وحرارته. ستتعرف في هذه المدونة على مدى تأثير درجات الحرارة المرتفعة على بطارية سيارتك وبعض النصائح التي تمكنك من التعامل معها بأفضل طريقة ممكنة.",
    descriptionHeading: "",
    conclutionHeading: "",
    conclutionDescription:
      "وفي نهاية المدونة ننصحك بشدة بإتباع القواعد السابقة والإلتزام بها، حفاظاً على سلامتك من التوقف في منتصف الطريق وسلامة سيارتك أيضاً، فكلها قواعد بسيطة التي يمكنك الالتزام بها بسهولة. في حال كنت قلقاً بشأن تعطل بطارية سيارتك أثناء القيادة، أو قد تحتاج إلى بطارية جديدة بمواصفات عالية، قم بتحميل تطبيق AC Xerox، التطبيق الأول في المملكة لبطاريات السيارات الأكثر كفاءة وعالية الجودة مع المواصفات العالمية والتي تمكنك من اختيار البطارية المناسبة لسيارتك وتوصيلها لك في أي مكان بالمملكة مع خدمة التركيب والتوصيل مجاناً.",
    resonArr: [
      {
        heading: "كيف تؤثر الحرارة المرتفعة على بطارية سيارتك؟",
        description:
          "تبدو الحياة أكثر صعوبة عندما تضطر للعيش في مناطق حارة، لأنك فجأة تحتاج إلى كوب من الماء البارد بعد نزهة قصيرة بالقرب من منزلك! الحرارة تسبب لك الجفاف إلى حد كبير، والأمر نفسه مع بطارية سيارتك، حيث وجد أن درجات الحرارة المرتفعة يمكن أن تؤدي إلى تبخر السوائل داخل البطارية وإضعاف شحنها، مما يعني جفافها وتلفها. بالإضافة إلى ذلك، يمكن أن تؤدي درجات الحرارة المرتفعة إلى تسريع عملية التآكل، مما يعني تلف البنية الداخلية للبطارية وفقدانها بالكامل. لاحظ أن معظم بطاريات السيارات المغلقة التي لا تحتاج إلى صيانة تحتوي على مؤشر لمستوى الماء داخل البطارية ليسمح لك بمعرفة ما إذا كانت المياه كافية أم منخفضة. ولهذا عليك أن تبذل بعض الجهد للمحافظة على بطارية سيارتك خلال درجات الحرارة المرتفعة خلال العام، بغض النظر عن المكان الذي تعيش فيه، وهو ما قد لا يمكّنك من تجنب درجات الحرارة المرتفعة، ولكن يمكنك الاستفادة من النصائح التالية للحفاظ على سيارتك البطارية وإطالة عمرها قدر الإمكان، لأن هناك بعض السلوكيات والعادات التي يمكن أن تضر بطارية سيارتك بشكل خطير.. انتبه لها، واتبع النصائح التالية.",
        id: 0,
      },
      {
        heading: "",
        description:
          "1. تجنب الرحلات القصيرة والقيادة لمسافات متقاربة خاصة في فصل الصيف، لأن ذلك لا يوفر للبطارية الوقت الكافي لإعادة شحنها بالكامل بين الرحلات القصيرة وفترات التوقف، مما يؤدي إلى ضعف الأداء وتلفها في وقت قصير.",
        id: 1,
      },
      {
        heading: "",
        description:
          "2. Make sure to turn off the internal and external lights when leaving the car, because high temperatures lead to high self-discharge of the battery, and therefore you will have to charge the battery many times even if it is stopped, and do not operate it frequently.",
        id: 2,
      },
      {
        heading: "",
        description:
          "3. حافظ على نظافة البطارية وأسلاكها طوال الوقت، لأن الأوساخ تتراكم بسرعة، وتعيق شحن البطارية، مما يؤدي إلى استنزافها وسوء الشحن.",
        id: 3,
      },
      {
        heading: "",
        description:
          "4. تجنب استخدام الأجهزة الإلكترونية مثل الراديو عند إيقاف تشغيل محرك السيارة.",
        id: 4,
      },
      {
        heading: "",
        description:
          "5.Park your car in the shade or in the garage to protect it from direct sunlight.",
        id: 5,
      },
      {
        heading: "",
        description:
          "6. تحتوي العديد من البطاريات على عازل حراري يحميها من درجات الحرارة المرتفعة. تأكد من تثبيت العازل في مكانه بقوة.",
        id: 6,
      },
      {
        heading: "",
        description:
          "7. انتبه إلى العلامات التي تشير إلى وجود مشكلة في بطارية سيارتك أو أنها تحتاج إلى صيانة.",
        id: 7,
      },
    ],
    boldHeading: {
      boldDesc:
        "ولحسن الحظ فإن أغلب هذه المؤشرات أصبحت واضحة ومعروفة للجميع، وهي تنبهك للمشكلة في وقت مبكر لتجنب مخاطر التوقف في منتصف الطريق، ومن أبرز المؤشرات التي يجب عليك الانتباه إليها :",
      bulletPoints: [
        {
          title: "المحرك يعمل بشكل أبطأ من المعتاد أثناء تشغيل السيارة.",
          id: 0,
        },
        {
          title: "إضاءة مؤشر فحص المحرك أو مؤشر البطارية على لوحة القيادة.",
          id: 1,
        },
        { title: "انخفاض مستوى السائل داخل البطارية", id: 2 },
        { title: "انتفاخ في شكل البطارية", id: 3 },
        { title: "تآكل أقطاب البطارية", id: 4 },
        { title: "الأضواء الداخلية والمصابيح الأمامية ضعيفة", id: 5 },
        { title: "عمر البطارية لديك أكثر من ثلاث سنوات", id: 6 },
      ],
    },
  },
  balanceTxt: "الرصيد (الاسترداد النقدي)",
  balanceSimpTxt: "توازن",
  supportServiceTxt: "خدمات الدعم (شحن البطارية والضمان)",
  currencyTxt: "ريال سعودي",
  ordertTxt: "طلباتي",
  cartTxt: "عربة التسوق",
  favoriteToastTxt1:"تمت إضافة المنتج إلى المفضلة",
  favoriteToastTxt2:"هذا المنتج موجود بالفعل في المفضلة لديك",
addsubscriptionTxt:"إضافة خدمة الفحص والاشتراك والضمان.",
youSaveTxt1:" لقد أنقذت",
youSaveTxt2:" ريال سعودي الآن!",

  totalTxt: "المجموع",
  discountTxt:"تخفيض",
  serviceChargeTxt: "رسوم الخدمة",
  subTotalTxt:"المجموع الفرعي",
  TaxTxt:"ضريبة",
  priceTxt:"سعر",
  warrantyChargeTxt:"الاشتراك",
  completOrder: "اكمل الطلب",
  shopNowTxt: "تسوق الآن",
  emptyCrtTxt: "العربة فارغة، تسوق الآن",
  emptyFvrtTxt: "سيتم عرض منتجاتك المفضلة هنا",
  addFavriteTxt: "اضافة الى المفضلة",
  addedFavriteTxt: "تمت إضافتها إلى المفضلة",
  nameInputTxt: "الاسم",
  emailInputTxt: "البريد إلكتروني",
  phoneInputTxt: "رقم الهاتف.",
  messageInputTxt: "رسالة",
  changeBatteryTxt: " تغيير البطارية",
  maintainBatteryTxt: "صيانة البطارية",
  WarentyBatteryTxtCharge: "الضمان وشحن البطارية",
  senBtnTxt: "ارسال",
  itemNumTxt: "رقم الصنف ",
  downloadAppTxtNew: "قم بتنزيل التطبيق الآن",
  AmpereTxt: "أمبير ",
  lengthTxt: "الطول ",
  widthTxt: "العرض",
  heightTxt: "الارتفاع ",
  totalHeightTxt: "الإرتفاع الإجمالي",
  coolEngTxt: "تدوير طاقة بارد",
  orderYourTxt: "اطلب سيارتك ",
  orderYourTxt2: " الان التوصيل والتركيب مجانا",
  batteryDescTxt: "بطارية سيارة الكترونية صناعة ماليزية لا تحتاج الى صيانة",
  oilDescTxt: "صناعة ماليزية زيت سيارات إلكتروني لا يحتاج إلى صيانة",
  tireDescTxt: "إطار سيارة إلكتروني صنع في ماليزيا ولا يحتاج إلى صيانة",
  helpArray: [
    {
      title: "ما هي أنواع البطاريات التي نقدمها؟",
      subHeading:
        "نقدم جميع أنواع بطاريات ac zurex المصنوعة في ماليزيا والتي تدوم لمدة عام كامل، حيث أننا الوكيل الحصري لهذه العلامة التجارية في المملكة العربية السعودية.",
      id: 0,
    },
    {
      title: "وماذا عن نوعية البطاريات",
      subHeading:
        "جميع المنتجات التي نبيعها تلبي المتطلبات التي حددتها الدولة المعنية في المملكة العربية السعودية",
      id: 1,
    },
    {
      title: "لماذا هناك فرق كبير في الأسعار",
      subHeading: "الفرق يرجع إلى جودة وجودة البطاريات.",
      id: 2,
    },
    {
      title: "كيف يمكنني شراء بطارية من متجر ac zurex",
      subHeading:
        "أدخل بياناتك على المتجر، ثم اختر البطارية المفضلة إلى قائمة العربة، ثم املأ معلومات الدفع، وسنقوم بالتواصل معك وإرسال البطارية إلى مكانك مع فريق التركيب لدينا.",
      id: 3,
    },
    {
      title: "هل البطارية تحت الضمان",
      subHeading:
        "تأتي كل بطارية مع ضمان قياسي. ويعتبر الضمان لاغيًا إذا تم استخدام البطارية بطريقة خاطئة",
      id: 4,
    },
    {
      title: "هل يمكنني شراء بطارية منك وشحنها إلى بلدي",
      subHeading:
        "نعم يمكننا شحن كمية كبيرة لكم عن طريق شركات الشحن الخاصة بكم. يرجى الاتصال بنا عبر البريد الإلكتروني أو الدعم الفني",
      id: 5,
    },
    {
      title: "هل هناك استرجاع واستبدال",
      subHeading:
        "لا يوجد استرجاع أو استبدال ولكن فقط حسب بطاقة الضمان المرفقة مع البطارية",
      id: 6,
    },
    {
      title: "هل يمكن استبدال بطارية سيارتي القديمة ببطارية ايه سي زوركس؟",
      subHeading:
        "نعم يمكنك استبدال بطاريتك ببطارية من ايه سي زوركس بخصم 30 ريال على الكود",
      id: 7,
    },
  ],
aboutUsArray: [
    {
      title: "من نحن",
      subHeading:
        "تطبيق Zurex هو تطبيق شامل لشركة إسماعيل الجمان الحمراني للتجارة، الرائدة في مجال بطاريات السيارات، إطارات السيارات واستيراد قطع غيار السيارات وإكسسواراتها على مستوى المملكة العربية السعودية. تمتد أنشطتنا وخدماتنا إلى نطاق واسع في جميع أنحاء المملكة من خلال فروعنا. نحن الوكيل الحصري لعلامتي AC ZUREX وCAMEL في المملكة.",
      id: 0,
    },
    {
      title: "خدمتنا",
      subHeading:
        "نعمل في شركة إسماعيل الجمان الحمراني للتجارة على تقديم أفضل أنواع بطاريات السيارات، إطارات السيارات، قطع الغيار والإكسسوارات لجميع عملائنا بالجودة المطلوبة. نحن من رواد موزعي مختلف العلامات التجارية من البطاريات والإطارات من كوريا، تايوان، إندونيسيا، تايلاند، الصين، وصناعة محلية.",
      id: 1,
    },
    {
      title: "رؤيتنا",
      subHeading:
        "تهدف المؤسسة إلى أن تكون الرائدة في مجالها في تجارة الجملة والتجزئة لمنتجات السيارات وقطع الغيار والإكسسوارات، وبيع بطاريات وإطارات السيارات والشاحنات عالية الجودة في المملكة. وتسعى لتحقيق رؤيتها من خلال النمو المستدام عبر الابتكار في التطوير والازدهار مع أفضل المنتجات العالمية وتوفير قوة عاملة متقدمة من المهندسين والمستشارين.",
      id: 2,
    },
    {
      title: "أهدافنا",
      subHeading:
        " تقديم أفضل المنتجات ذات الجودة العالية وخدمات ما بعد البيع الفعالة على المستوى الوطني، ونعمل على تطويرها بالتوازي مع الأسواق المحلية والدولية بمنتجات عالية الجودة وصديقة للبيئة وبضمان. رضا العملاء هو هدف شركتنا، ونسعى باستمرار للوصول إلى الأسواق العالمية واستكشافها",
      id: 3,
    },
    {
      title: "قيمنا",
      subHeading:
        "الثروة الحقيقية للشركة تكمن في علاقاتها الراسخة. نعتبرها الركيزة الأساسية في بناء وإدارة شركتنا، وهي من الثوابت التي لا نحيد عنها، ونسعى لفهم متطلبات واحتياجات عملائنا.",
      id: 4,
    },
  ],
  favriteTxt: "المفضلة",
  carTypeTxt: "نوع السيارة",
  carCategTxt: "اختر فئة السيارة",
  carModalTxt: "اختر طراز السيارة",
  plateNumTxtInp: "كتابة رقم اللوحة",
  addCarsTxt: "أضف سيارات",
  addCarsBtn: "أضف سيارة",
  nextBtn: "التالي",
  loadingBtn: "تحميل...",
  pay4Txt:"ادفع في 4. بدون فوائد ولا رسوم.",

payment1Txt: "الدفع الإلكتروني",
payment2Txt: "الدفع نقداً",
payment3Txt: "شركات التقسيط",
payment4Txt: "شركات التقسيط",

  plsSlctCarTxt: "يرجى اختيار السيارة قبل تحديد فئة المنتج.",

  addToCartTxt: "أضف إلى السلة",
  enterYourCarDataTxt: "أدخل بيانات سيارتك",
  enterCarManual: "ادخل السيارة يدويا",
  enterCarILM: "أدخل رقم اللوحة",
  enterCarPlateDetails: "أدخل تفاصيل لوحة السيارة",
  selectcarmethod: "حدد طريقة ادخال السيارة",
  enterplateAlphabets: "أدخل الحروف الهجائية للوحة",
  enterplateNumbers: "أدخل رقم اللوحة ",
  vehicledetailsTxt: "تفاصيل السيارة",
  makeTxt: "الشركة:",
  modelTxt: "الموديل:",
  modelyearTxt: "سنة الصنع:",
  registrationtypeTxt: "نوع التسجيل:",
  vehicalclassTxt: "فئة المركبة:",
  vehicalIdNumberTxt: "رقم هوية المركبة:",
  cylinderTxt: "اسطوانة:",

  downloadAppTxt: "قم بتنزيل التطبيق الآن",
  chooseShippingWay: "معلومات الشحن",
  backBtnTxt: "خلف",
  yourAddress: "عنوانك",
  strretTownAddress: "الشارع، المدينة، الحي",
  selectDeliveryDate: "حدد تاريخ الاستلام",
  zipcode:"الرمز البريدي",
  userReviewTxt: "الخدمات ممتازة جداً والطلب وصل بشكل جيد وأسرع وبجودة أكثر من رائعة، أتمنى لكم التوفيق",
  tabbyPaymentCancel : 'لقد ألغيت الدفعة. فضلاً حاول مجددًا أو اختر طريقة دفع أخرى.',
  tabbyPaymentFailed : 'نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.',
  tabbyGeneralError: 'نأسف، تابي غير قادرة على الموافقة على هذه العملية. الرجاء استخدام طريقة دفع أخرى.',
  tabbyOrderHighError: 'قيمة الطلب تفوق الحد الأقصى المسموح به حاليًا مع تابي. يُرجى تخفيض قيمة السلة أو استخدام وسيلة دفع أخرى.',
  tabbyTooLowError: 'قيمة الطلب أقل من الحد الأدنى المطلوب لاستخدام خدمة تابي. يُرجى زيادة قيمة الطلب أو استخدام وسيلة دفع أخرى.'

};
const isEnglish = true;
const textString = isEnglish ? englishString : arabicString;
export { textString };
export { englishString, arabicString };

const getTextString = (isArabic) => (isArabic ? arabicString : englishString);

export { getTextString };
